import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useCallback, useMemo, useState } from 'react';
// Layout
import Layout from '../components/layout';
// React Helmet SEO
import SEO from '../components/seo';
import * as styles from '../modules/medical-partners.module.scss';
import HeaderPage from '../components/repeatable/headerPage';
import MedicalPartners from '../components/static/medical-partners';

function IndexPage({ data }) {
  const {
    pageMedicalPartnersYaml: { title, description, seo, partners },
  } = data;

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} canonical={seo.canonical} />
      {/* Header */}
      <div className={styles.baseStyles}>
        <HeaderPage title={title} description={description} />
      </div>

       <MedicalPartners />
       
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  {
    pageMedicalPartnersYaml {
      title
      description

      seo {
        title
        description
        canonical
      }
    }
  }
`;
