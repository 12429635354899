import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './modules/medical-partners.module.scss';
import { dataEdge } from '../../functions';
function MedicalPartners({ data }) {
  const { allStaticMedicalPartnersYaml } = data;
  const {
    node: { partners },
  } = dataEdge(allStaticMedicalPartnersYaml);

  return (
    <section className={style.medicalPartners}>
      <h1 className={style.title}>Nos partenaires médicaux</h1>
      <div className={style.partners}>
        {partners.map((partner, index) => (
          <div className={style.partner} key={index}>
            <div className={style.content}>
              <div className={style.text}>
                <h2>{partner.name}</h2>
                <h3>{partner.title}</h3>
                <p className={style.description} dangerouslySetInnerHTML={{ __html: partner.description }} />
              </div>
            </div>
            <div className={style.image}>
              <GatsbyImage image={partner.image.childImageSharp.gatsbyImageData} alt={partner.name} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <MedicalPartners data={data} {...props} />}
      query={graphql`
        {
          allStaticMedicalPartnersYaml {
            edges {
              node {
                title
                description

                seo {
                  title
                  description
                  canonical
                }

                partners {
                  name
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                  }
                  title
                  description
                }
              }
            }
          }
        }
      `}
    />
  );
}
