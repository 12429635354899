// extracted by mini-css-extract-plugin
export var baseStyles = "medical-partners-module--baseStyles--yCRxF";
export var container = "medical-partners-module--container--fphcA";
export var containerMobile = "medical-partners-module--containerMobile--05Gmx";
export var description = "medical-partners-module--description--VJF51";
export var descriptionMobile = "medical-partners-module--descriptionMobile--kuis9";
export var imagePartner0 = "medical-partners-module--imagePartner0--StlIV";
export var imagePartner1 = "medical-partners-module--imagePartner1--gaNLw";
export var imagePartner2 = "medical-partners-module--imagePartner2--jR+NI";
export var imagePartner3 = "medical-partners-module--imagePartner3--vYIFE";
export var imagePartnerMobile = "medical-partners-module--imagePartnerMobile--VcySp";
export var partener = "medical-partners-module--partener--Hkwc7";
export var partenerMobile = "medical-partners-module--partenerMobile--qpc6z";
export var partnersContainer = "medical-partners-module--partnersContainer--FTQAZ";
export var partnersContainerMobile = "medical-partners-module--partnersContainerMobile--zMWpx";
export var title = "medical-partners-module--title--MRF8s";
export var titleMobile = "medical-partners-module--titleMobile--iDA3d";